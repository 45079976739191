*,
::after,
::before {
  margin: 0;
  padding: 0;
  /* box-sizing: border-box; */
}
.wrapper {
  display: flex;
  /* justify-content: center; */
  align-items: center;
  width: 100%;
  flex-direction: column;
  margin: 0;
}
#pdf {
  height: 841px;
  width: 595px;
  box-sizing: border-box;
  padding: 8px;
  font-size: 7.5px;
  background-color: #fff;
  font-family: Arial;
  color: #000 !important;
}
.box {
  display: flex;
  align-items: center;
  margin: 0;
}

/* p {
  padding: 0 0 0.5px 0;
} */
.parahead {
  font-size: 6.5px;
  letter-spacing: 0.1px;
  color: "#000";
}
.flex {
  display: flex;
  padding: 1px 0;
}
.flexR {
  display: flex;
  /* padding: 1px 0; */
  align-items: center;
}
.flexR1 {
  display: flex;
  padding-bottom: 2px;
  align-items: center;
}

.patientinfo1 {
  display: flex;
  justify-content: space-between;
  width: 98%;
  padding: 1px;
}
.patientinfo2 {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  width: 98%;
  /* padding: 1px; */
}
.patientinfo22 {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  /* width: 90%; */
  /* padding: 1px; */
}
.patientinfo3 {
  width: 98%;
  display: flex;
  justify-content: space-between;
  padding: 1px;
}
.patientinfo4 {
  width: 95%;
  display: flex;
  justify-content: space-between;
  padding: 1px;
}
.flexstart {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}
.flex_spacebtw {
  /* width: 220px; */
  display: flex;
  /* gap: 5px; */
  /* justify-content: space-between; */
  align-items: center;
}
.flexRF {
  display: flex;
  width: 100%;
}
.question3 {
  display: flex;
  width: 100%;
  height: fit-content;
}
.flexRG {
  display: flex;
  gap: 10px;
  width: 100%;
  /* justify-content: space-around; */
}
.phoneinfo {
  width: 75%;
  display: flex;
  justify-content: space-between;
  height: 10px;
}
.diseaseDiagonsis {
  width: 100%;
  display: flex;
}
.flexRH-F {
  display: flex;
  width: 50%;
}
hr {
  margin: 0;
  height: 0;
  border-top: none;
  border-bottom: 0.5px solid #000;
}
.flexRH-Fborder {
  display: flex;
  width: 50%;
  border-left: 0.1px solid #000;
}
.flexMarginLeft {
  display: flex;
  margin-left: 12px;
}
.MarginLeft {
  margin-left: 10px;
}
.grid {
  display: grid;
  width: 100%;
  /* margin: 0 auto; */
  grid-gap: 0rem;
  /* grid-template-columns: repeat(3, 1fr); */
  grid-template-columns: 31% 37% 31%;
  /* flex-wrap: wrap; */
}
/* input[type="checkbox"] {
  position: relative;
  cursor: pointer;
  margin: 0 3px;
  width: 8px;
  height: 8px;
  border-radius: 0;
  top: 0.6px;
} */
/* }
input[type="checkbox"]:before {
  content: "";
  display: block;
  position: absolute;
  width: 7.7px;
  height: 7.7px;
  top: 0;
  left: 0;
  border: 1px solid #000000;

  background-color: white;
}
input[type="checkbox"]:checked:after {
  content: "";
  display: block;
  width: 2px;
  height: 6px;
  border: solid black;
  border-width: 0 1px 1px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
  position: absolute;
  top: 0.5px;
  left: 3px;
} */
.checkbox {
  position: relative;
  cursor: pointer;
  margin: 0 3px;
  width: 13px;
  height: 11px;
  border-radius: 0;
  top: 0.6px;
  border: 0.1px solid black;
  /* padding-bottom: 2px; */
}
.checkboxtick {
  display: block;
  width: 3.5px;
  height: 9px;
  border: solid black;
  border-width: 0 0.1px 0.1px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
  position: absolute;
  top: -0.2px;
  left: 4px;
}
table {
  border-collapse: collapse;
  border-spacing: 0px;
}

#td {
  border: 0.1px solid #000;
  text-align: center;
  width: 13.5px;
  height: 11px;
  font-size: 7px;
  padding: 0;
  position: relative;
  font-family: Arial;
  color: #000;
  top: 0.5px;
}

td.tr1 {
  border: none;
  text-align: left;
  width: 70px;
}
td.tr2 {
  border: none;
  text-align: left;
}
